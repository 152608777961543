<template>
  <section class="content_container plan_confirm">
    <div class="thank_you text-center">
      <div class="img">
        <img :src="thankYouImage" width="45%" alt="Corenroll">
      </div>
      <div class="content mt-3">
        <!-- <h1>
          <span class="head_grey">Thank you for choosing our</span><br>Insurance Plans.
        </h1> -->
        <h2 class="mb-3">Your plans will be effective as of {{effective}}</h2>
        <div class="proceed-btn">
          <button class="back btn-radius me-0" type="button" id="backCart" name="button" @click="navigatePrevious">Back to Cart</button>
          <button class="proceed btn-radius" type="button" id="beginEnroll" name="button" @click="nextPage">Proceed to Enrollment</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import axios from 'axios';


export default {
    name: "Confirmation",
    props:{
    effective: String,
  },
    data: function () {
      return {
        tempId : window.localStorage.getItem('tempId'),
        thankYouImage: require(process.env.VUE_APP_THANK_YOU)
      }
    },
    mounted() {
        let app = this;
        app.tempId = window.localStorage.getItem('tempId');
    },
    methods: {
        nextPage: function() {
          this.$emit('Checkoutcart');
          localStorage.setItem('enrollment','new_enrollment');
          this.$router.push('enrollment/personal-info');
        },
        navigatePrevious: function() {
          this.$emit('hidePopUp');
        },


    }
}
</script>

<style lang="scss" scoped>
.btn-radius {
  border-radius: 50px !important;
}
</style>
